import "./styles.scss";
import Modal from "components/modal";
import { useState } from "react";
import { ReactComponent as RaiseCloseIcon } from "assets/close-icon.svg";
import { Button } from "components/button";
import { redirectTo } from "services/redirect";
import { EXTERNAL_LINKS } from "routes/paths";
import { useBannerVisibility } from "./hooks/useBannerVisibility";

export const GCXBanner = () => {
  const [scriptAdded, setScriptAdded] = useState(false);
  const { setGlobalBannerCookie, shouldShowBanner: canShowBanner } = useBannerVisibility();
  const [shouldShowBanner, setShouldShowBanner] = useState(canShowBanner);

  const addGtmScript = () => {
    const noscript = document.createElement('noscript')
    const iframe = document.createElement('iframe')
    iframe.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=GTM-W4JB8CRR")
    iframe.setAttribute("height", "0")
    iframe.setAttribute("width", "0")
    iframe.setAttribute("style", "display:none;visibility:hidden")
    noscript.appendChild(iframe)
    document.body.appendChild(noscript)
  }
  const handleButtonClick = () => {
    if (!scriptAdded) {
      addGtmScript()
      setScriptAdded(true)
    }
    redirectTo({ link: EXTERNAL_LINKS.GCX_MARKETPLACE, isNewTab: true })
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setShouldShowBanner(false);
    setGlobalBannerCookie();
  };

  return (
    <div>
      <Modal show={shouldShowBanner} onClose={handleCloseModal}>
        <div className="modal">
          <div className="modal-header">
            <button onClick={handleCloseModal} className="close-button">
              <RaiseCloseIcon />
            </button>
          </div>
          <div className="modal-content">
            <div className="modal-text">
              <p className="gcx-title">
                Looking for discounted gift cards? Raise Marketplace is now GCX.
              </p>
              <p className="gcx-subtitle">
                It&apos;s a NEW look, but same great savings on discounted gift
                cards. Save the new domain to make it easier to shop, or
                checkout on the GCX app.
              </p>
            </div>
            <div className="modal-button">
              <Button
                variant="title-lg"
                customClassName="go-to-gcx-button"
                onClick={handleButtonClick}
              >
                <p className="gcx-button-title"> Go to </p>
                <span className="gcx-button-link">gcx.raise.com</span>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
