import React from 'react';
import './index.scss';

const Modal = ({ show, onClose, children }) => {
    if (!show) {
      return null;
    }
    const handleOverlayClick = (e) => {
        if (e.target.className === 'modal-overlay') {
          onClose();
        }
      };
    return (
      <div className="modal-overlay" onClick={handleOverlayClick}>
            {children}
      </div>
    );
  };
  
  export default Modal;
