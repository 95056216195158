const getCookie = (key) =>
  document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`)?.pop() || null;

const MILISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

const setCookie = ({ name, value, days = 1 }) => {
  const date = new Date();
  date.setTime(date.getTime() + days * MILISECONDS_IN_A_DAY);
  const expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export { getCookie, setCookie };
