import { Route, Routes } from "react-router-dom";
import { INTERNAL_PATHS } from "./paths";
import { HomePage } from "pages/home";
import { AboutUs } from "pages/about-us";
import { Press } from "pages/press";

const { HOME, ABOUT_US, PRESS, MISC } = INTERNAL_PATHS;

export const AppRouter = () => (
  <Routes>
    <Route path={HOME} element={<HomePage />} />
    <Route path={ABOUT_US} element={<AboutUs />} />
    <Route path={PRESS} element={<Press />} />
    <Route path={MISC} element={<HomePage />} />
  </Routes>
);
