export const PRESS_RELEASE_ITEMS = [
  {
    title: "Gift Card Leader Raise Brings Crypto Payments to Mainstream Retail",
    subtitle: "Raise Team | Aug 15, 2024",
    content:
      "Raise, a leading payments provider and innovator in the global gift card industry, today announced the acceptance of crypto as a form of payment in the Raise App",
    cta: "https://www.prnewswire.com/news-releases/gift-card-leader-raise-brings-crypto-payments-to-mainstream-retail-302223750.html?tc=eml_cleartime",
  },
  {
    title: "Raise Announced as Payments Provider for the Polkadot Mobile App",
    subtitle: "Raise Team | Jul 18, 2024",
    content:
      "Raise's payment infrastructure enables $DOT payments at over one million stores and websites",
    cta: "https://www.prnewswire.com/news-releases/raise-announced-as-payments-provider-for-the-polkadot-mobile-app-302200024.html",
  },
];
