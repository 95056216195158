import { useAppContext } from "context/AppContext";
import { BigNumbers } from "./sections/big-numbers";
import { HeroScene } from "./sections/hero-scene";
import { ProductsCarousel } from "./sections/products-carousel";
import { FutureCarousel } from "./sections/future-carousel";
import { InformationalText } from "./sections/informational-text";
import { CommunityBanner } from "./sections/community-banner";
import { NotificationBanner } from "./sections/notification-banner";
import { FEATURE_FLAG } from "services/feature-flag";
import "./styles.scss";
import { GCXBanner } from "./sections/gcx-banner";

export const HomePage = () => {
  const { themeView } = useAppContext();
  const {
    shouldShowCommunityBanner,
    shouldShowFutureCarousel,
    shouldShowInformationalText,
  } = FEATURE_FLAG;

  return (
    <div className="content" data-theme={themeView}>
      <NotificationBanner />
      <GCXBanner />
      <HeroScene />
      <BigNumbers />
      <ProductsCarousel />
      {shouldShowInformationalText && <InformationalText />}
      {shouldShowFutureCarousel && <FutureCarousel />}
      {shouldShowCommunityBanner && <CommunityBanner />}
    </div>
  );
};
