import { Button } from "components/button";
import { redirectTo } from "services/redirect";
import { Typography } from "components/typography";
import arrowRight from "assets/arrow-right.svg";
import "./index.scss";

export const BusinessLink = ({ image, title, link, customClassName = '' }) => {
  return (
    <div className={`business-link ${customClassName}`}>
      <div className="image-container">
        <img
          src={image}
          alt={`Learn more about ${title}`}
          className="business-image"
          loading="lazy"
        />
      </div>
      <div className="business-description">
        <Typography
          tag="strong"
          semibold
          variant="title-xsm"
          customClassname="business-title"
        >
          {title}
        </Typography>
        <Button
          variant="secondary"
          onClick={() => {
            redirectTo({ link });
          }}
          customClassName="learn-more-button"
        >
          Learn more
          <img
            src={arrowRight}
            alt="Learn more arrow"
            className="arrow-right"
          />
        </Button>
      </div>
    </div>
  );
};
