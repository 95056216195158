import { useAppContext } from "context/AppContext";
import { BusinessLink } from "../business-link";
import { INTERNAL_PATHS } from "routes/paths";
import businessRewards from "assets/images/business-rewards.png";
import businessApi from "assets/images/business-api.png";
import consumerModule from "assets/images/consumer-module.png";
import "./index.scss";

export const SecondaryAreaContent = () => {
  const { isConsumerView } = useAppContext();

  return (
    <div className="secondary-area-content">
      {isConsumerView ? (
        <div className="consumer-area">
          <img src={consumerModule} alt="Offers for the consumer" loading="lazy" className="consumer-image" />
        </div>
      ) : (
        <div className="business-area">
          <BusinessLink
            title="Rewards and Incentives"
            link={INTERNAL_PATHS.GIFTING_PORTAL}
            image={businessRewards}
            customClassName="rewards-link"
          />
          <hr />
          <BusinessLink
            title="API Solutions"
            link={INTERNAL_PATHS.API_SOLUTIONS}
            image={businessApi}
          />
        </div>
      )}
    </div>
  );
}
