import { COOKIES } from "constants/cookies";
import { getCookie, setCookie } from "services/cookies";

export const useBannerVisibility = () => {
  const hasGlobalBannerCookie = () =>
    !!getCookie(COOKIES.GLOBAL_BANNER_VISIBILITY);

  const setGlobalBannerCookie = () => {
    setCookie({ name: COOKIES.GLOBAL_BANNER_VISIBILITY, value: true, days: 30});
  };

  return {
    shouldShowBanner: !hasGlobalBannerCookie(),
    setGlobalBannerCookie,
  };
}
